export const davidpaarup = {
    "name": "David Paarup",
    "profile": "Software engineer with a sense for detail, quality and team work. Always striving to learn.",
    "experience": [
        { 
            "from": 2022, 
            "location": "Oslo",
            "company": {
                "name": "LetsReg", 
                "url": "https://letsreg.co/uk"
            }, 
            "position": "Full stack developer", 
            "type": "Full-time", 
            "description": "Design and development of the web application and services using Angular, .Net, MSSQL, Kubernetes and Azure.",
            "current": true
        },
        { 
            "from": 2019,
            "to": 2022, 
            "location": "Copenhagen",
            "company": { 
                "name": "Danske Bank", 
                "url": "https://danskebank.dk",
            },
            "position": "Software engineer", 
            "type": "Full-time", 
            "description": "Design and development of a sustainability related web application and services using Angular, .Net and MSSQL."
        },
        {
            "from": 2018,
            "to": 2019, 
            "location": "Madrid", 
            "company": {
                "name": "IIC",
                "url": "https://www.iic.uam.es"
            }, 
            "position": "Software engineer", 
            "type": "Part-time", 
            "description": "Modularisation of large Bash scripts using Python, converting them to micro-services orchestrated by Kubernetes."
        },
        {
            "from": 2018,
            "to": 2018, 
            "location": "Madrid", 
            "company": {
                "name": "Flexxible",
                "url": "https://www.flexxible.com",
            },
            "position": "Software developer", 
            "type": "Internship", 
            "description": "Automation of virtual machine configuration and deployment using Chef and VMware."
        }
    ],
    "education": [
        {
            "title": "Computer science",
            "center": {
                "name": "UAM",
                "url": "https://www.uam.es",
            },
            "location": "Madrid",
            "level": "Bachelor",
            "from": 2015,
            "to": 2019,
            "description": "General hardware and software knowledge."
        }
    ],
    "skills": [
        ".Net", "Angular", "SQL", "Azure", "Kubernetes"
    ],
    "languages": [
        "Spanish", "English", "Danish"
    ],
    "birthDate": "1992-12-24",
    "emailAddress": "hi@davidpaarup.com",
    "location": "Madrid"
}
